import React from "react"
import getFirebase from "../utils/firebase"

const Test = () => {
    const callCloudFunction = (text) => {
        const testFunction = getFirebase().functions().httpsCallable('test-createLead');

        testFunction({text}).then(result => {
            console.log(result)
        }).catch(error => {
            console.log(error.code);
            console.log(error.message);
            console.log(error.details);
        });
    };

    return (
        <div style={{padding: "10px", cursor: "pointer"}} onClick={() => callCloudFunction("joel")}>
            click
        </div>
    )
};

export default Test